import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';

import { ProjectHeader } from '../../components/Projecten/ProjectHeader';
import { ProjectIntro } from '../../components/Projecten/ProjectIntro';
import { ProjectMobile } from '../../components/Projecten/ProjectMobile';
import { ProjectQuote } from '../../components/Projecten/ProjectQuote';
import { ProjectTextContainer } from '../../components/Projecten/ProjectTextContainer';
import { ProjectVideo } from '../../components/Projecten/ProjectVideo';
import { ProjectsCarousel } from '../../components/ProjectsCarousel/ProjectsCarousel';
import { Root } from '../../components/Root';
import { IgnoreMaxWidth } from '../../components/UI/IgnoreMaxWidth';
import { Text } from '../../components/UI/Text';
import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { getImageByAlt } from '../../utils/getImageByAlt';

export const query = graphql`
  {
    allSanityProject(filter: {Client: {eq: "Arno Poppelaars"}}) {
      edges {
        node {
          Gallery {
            Alt
            asset {
              fluid(maxWidth: 2000) {
                aspectRatio
                sizes
                base64
                src
                srcSet
              }
            }
          }
        }
      }
    }
  }
`;

const arnoPoppelaars = ({ data }) => {
  const images = data.allSanityProject.edges[0].node.Gallery;

  const arnoMobile = getImageByAlt(images, 'arno-mobile');
  const arnoHeader = getImageByAlt(images, 'arno-header');
  const arnoDesktop1 = getImageByAlt(images, 'arno-desktop-1');
  const arnoDesktop2 = getImageByAlt(images, 'arno-desktop-2');
  const arnoBrandingDark = getImageByAlt(images, 'arno-branding-dark');
  const arnoBrandingLight = getImageByAlt(images, 'arno-branding-light');

  return (
    <>
      <ProjectHeader image={arnoHeader.asset.fluid.src} />
      <Root>
        <ProjectIntro tags="#web #graphicdesign #2020 #videograph #design" title="Light! Shoot! Edit! " />
        <DesktopImages>
          <StyledImg fluid={arnoDesktop1.asset.fluid} alt={arnoDesktop1.Alt} />
          <StyledImg fluid={arnoDesktop2.asset.fluid} alt={arnoDesktop2.Alt} />
        </DesktopImages>

        <MobileImage>
          <StyledMobileImg fluid={arnoMobile.asset.fluid} alt={arnoMobile.Alt} />
        </MobileImage>

        <ProjectTextContainer>
          <Text mode="projectText">
            Voor Video Content Creator Arno Poppelaars maakten we een website waarop hij zelf makkelijk nieuwe projecten kan toevoegen. 
            Zo kunnen potentiële klanten een steeds up-to-date portfolio zien. We werkten het volledige design uit, zowel de volledige website als een knap nieuw logo. 
          </Text>
          <br /><br />
          <Text mode="tag">
            Ga zelf eens kijken naar de video’s van deze beeldenkunstenaar op &nbsp;
            <Link to={'https://arnopoppelaars.be'} target="_blank">
              <u>Arnopoppelaars.be</u>
            </Link>
          </Text>
        </ProjectTextContainer>

        <ProjectVideo source="https://vimeo.com/412253252" color="white" />

        <DesktopScreenshots>
          <StyledImg fluid={arnoDesktop1.asset.fluid} alt={arnoDesktop1.Alt} />
          <StyledImg fluid={arnoDesktop2.asset.fluid} alt={arnoDesktop2.Alt} />
        </DesktopScreenshots>

        <BrandingContainer>
          <BrandingImg fluid={arnoBrandingDark.asset.fluid} alt={arnoBrandingDark.Alt} />
          <BrandingImg fluid={arnoBrandingLight.asset.fluid} alt={arnoBrandingLight.Alt} />
        </BrandingContainer>

        <ProjectMobileContainer>
          <ProjectMobile
            title="Onderhoudbaarheid"
            text="De video’s die Arno toevoegt op het CMS-systeem, worden automatisch geüpload op zijn website. Zo blijft zijn website altijd up-to-date met een minimale inspanning."
            mobileImage={arnoMobile}
            backgroundColor={'black'}
          />
        </ProjectMobileContainer>

        <ProjectQuote
          title='"Jouw beelden, mijn zorg"'
          tag="Arno Poppelaars"
          text="Video- & fotografie is mijn passie. Ik verzorg daarom jouw beelden met volledige overgave. Voor licht, geluid, beelden en montage kan je bij mij terecht." />

        <ProjectsCarousel forProject={true} carouselTitle="Er is meer." />
      </Root>
    </>
  );
};

export default arnoPoppelaars;

const StyledImg = styled(Img)`
  width: 100%;
  position: relative;

  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    width: 100%;
  `)}

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    width: 80%;
  `)}
`;

const DesktopImages = styled.div`
  display: none;
  margin-top: 10rem;

  ${StyledImg}:nth-child(1){
      margin-right: 20rem;
      margin-bottom: 5rem;
    }
    ${StyledImg}:nth-child(2){
        margin-left: 20rem;
    }

  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `)}
`;

const MobileImage = styled.div`
  margin-top: 5rem;
  
  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    display:none;
`)}
`;

const DesktopScreenshots = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  margin-top: 5rem;
  margin-bottom: 5rem;

  ${forBreakpoint(Breakpoint.TabletPortrait, css`
  display: none;
`)}
`;

const StyledMobileImg = styled(Img)`
  position: relative;
  width: 100%;
`;

const BrandingContainer = styled.div`
  ${IgnoreMaxWidth};
  margin-bottom: -5px;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    display: flex;
  `)}
`;

const ProjectMobileContainer = styled.div`
  display: none;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
   display: block;
  `)}
`;

const BrandingImg = styled(Img)`
    width: 100%;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    width: 50%;
  `)}
`;
