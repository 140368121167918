import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';

import { Text } from '../../components/UI/Text';
import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Column } from '../UI/Column';
import { Heading } from '../UI/Heading';
import { IgnoreMaxWidth } from '../UI/IgnoreMaxWidth';
import { Row } from '../UI/Row';

type Props = {
  title: string,
  text: string,
  mobileImage: any,
  backgroundColor: string
};

export function ProjectMobile({ title, text, mobileImage, backgroundColor }: Props) {
  return (

    <MobileExample backgroundColor={backgroundColor}>
      <Column breakpoints={{ [Breakpoint.Phone]: 12, [Breakpoint.TabletPortrait]: 6 }} >
        <MobileText>
          <div>
            <StyledTitle type="h2">
              {title}
            </StyledTitle>
            <Text mode="projectText">
              {text}
            </Text>
          </div>
        </MobileText>
      </Column>
      <Column breakpoints={{ [Breakpoint.Phone]: 12, [Breakpoint.TabletPortrait]: 6 }}>
        <PhoneImg fluid={mobileImage.asset.fluid} alt={mobileImage.Alt} />
      </Column>
    </MobileExample>
  );
}

const MobileExample = styled(Row) <{ backgroundColor }>`
  width: 100%;
  background-color: ${props => props.backgroundColor};
  ${IgnoreMaxWidth};
`;

const StyledTitle = styled(Heading)`
  margin-bottom: 2rem;
`;

const MobileText = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin: 0 auto;
  max-width: 300px;
  
  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    width: 70%;
    margin-top: 0;
    max-width: 400px;
`)}
`;


const PhoneImg = styled(Img)`
  width: 45%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 0 auto;
`;